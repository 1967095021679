<template>
  <div class="home1111">
    <!-- size="small" -->
    <a-table :columns="columns" :row-selection="rowSelection" rowKey="id" :pagination="pagination" @change="handleTableChange" :data-source="data" bordered>
    <template slot="customerCode" slot-scope="text, record">
      {{ text }}
    </template>
    <template slot="storageCode" slot-scope="text, record">
      {{ text }}
    </template>
    <template slot="trackingNo" slot-scope="text, record">
      {{ text }}
    </template>
    <template slot="option" slot-scope="text, record">
      <a-button type="primary" shape="circle" icon="edit" @click="startEditor(record)" />&nbsp;
      <a-popconfirm placement="topRight"
        title="确定要删除这条记录吗?"
        ok-text="删除"
        cancel-text="取消"
        @confirm="confirmDelete(record)"
        @cancel="cancel"
      >
        <a-button type="danger" shape="circle" icon="delete" />
      </a-popconfirm>
    </template>
    <template slot="lastStatus" slot-scope="text, record">
      <div v-if="text || record.lastStatusAt">
        <span style="color:#333">{{ text }}</span><br/>
        <span style="color:#aaa">{{ formatDate(record.lastStatusAt, 'yyyy-MM-dd') }}</span>
      </div>
      <div v-else><span style="color:#aaa">暂无轨迹信息</span></div>
      
    </template>
    <template slot="time" slot-scope="text,record">
      
      报表:&nbsp;{{ record.reportDate?formatDate(record.reportDate, 'yyyy-MM-dd'):'-' }}<br/>{{ formatDate(text, 'yyyy-MM-dd') }}
    </template>
    <template slot="title" slot-scope="currentPageData">
      <a-input addon-before="客户编号" style="width: 200px" v-model="searchInfo.customerCode" allow-clear />
      &nbsp;<a-input style="width: 200px" addon-before="入库单号" allow-clear v-model="searchInfo.storageCode" />
      &nbsp;<a-input addon-before="运单号" style="width: 200px" allow-clear v-model="searchInfo.trackingNo" />
      &nbsp;<a-button type="primary"  icon="search" @click="loadTrailDatas(1)" :loading="searchLoading">搜索</a-button>
      <div class="rightTools" > <a-button @click="startEditor({})" icon="plus">新增轨迹</a-button> &nbsp; 

      <a-upload
        name="file"
        :showUploadList = false
        accept=".xlsx"
        :disabled = "uploadInfo.status"
        :multiple="false"
        :action="baseURL+'/trail/import'"
        @change="importHandleChange"
      >
        <a-button :loading="uploadInfo.status" type="primary"  icon="import">导入数据</a-button>
        
      </a-upload>
      
      </div> 
    </template>
    <template slot="footer" slot-scope="currentPageData" >
      <div style="text-align:left;width:100%;height:100%">
        <a-space>
          <span>共: {{pagination.total}} 条记录</span>
          每页:
          <a-select v-model="pageSize" style="width: 70px" @change="pageSizeChange">
            <a-select-option value="5">
              5
            </a-select-option>
            <a-select-option value="10">
              10
            </a-select-option>
            <a-select-option value="20">
              20
            </a-select-option>
            <a-select-option value="50">
              50
            </a-select-option>
            <a-select-option value="100">
              100
            </a-select-option>
            <a-select-option value="200">
              200
            </a-select-option>
            <a-select-option value="500">
              500
            </a-select-option>
          </a-select>
          <span>条记录,已选择[ <span style="color:red;">{{selectRows.length}}</span> ]</span>
          <a-button @click="buildReport" :loading="reportBuilding" :disabled="reportBuilding">生成选择数据的报表</a-button>
          <a v-if="reportFile" target="_blank" :href="reportFile.src" >点击下载: {{reportFile.title}}({{reportFile.contentSize/1000}}Kb)</a>
      </a-space>
      <a style="float:right;display:block;width:120px;height:32px;" target="_blank" href="./template.xlsx">下载导入模板文件</a>
      </div>
    </template>
  </a-table>
  <a-drawer
      :title="editItem && editItem.id ? '修改[  '+editItem.trackingNo+'  ]轨迹':'新增轨迹'"
      :width="720"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      
      <a-form-model :model="editItem" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item label="客户编号">
              <a-input v-model="editItem.customerCode" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="入库号">
              <a-input v-model="editItem.storageCode" />
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item label="运单号">
              <a-input v-model="editItem.trackingNo" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="货运方式">
              <a-select v-model="editItem.trailType" placeholder="请选择">
                <a-select-option value="快陆运">
                  快陆运
                </a-select-option>
                <a-select-option value="陆运">
                  陆运
                </a-select-option>
                <a-select-option value="空运">
                  空运
                </a-select-option>
                <a-select-option value="铁运">
                  铁运
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item label="清关车/柜号">
              <a-input v-model="editItem.customerCode1" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="运输商">
              <a-input v-model="editItem.carrier" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item label="报表日期">
              <a-date-picker width="205" v-model="editItem.editReportDate" placeholder="请选择日期"  />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-list bordered 
            style="margin:18px 6px;"
            item-layout="horizontal"
            :data-source="editItem.steps"
            :locale="{emptyText:'无轨迹数据'}"
          >
          <div slot="header">
            轨迹列表  
            <div style="float:right;margin:-6px -10px;">
              <a-popover placement="topRight" width="300" v-model="addStepVisible" title="添加轨迹节点" trigger="click">
                <div slot="content" style="width:350px;">
                  <a-form-model :model="newStep" :label-col="{}" :wrapper-col="{}">
                    <a-form-model-item label="快速选择">
                      <a-select v-model="newStep.index" @change="stepDefineSelected" >
                        <a-select-opt-group v-for="stepDefine in stepDefines" v-bind:key="stepDefine.trailType" :label="stepDefine.trailType">
                          <!-- <span slot="label">Manager</span> -->
                          <a-select-option  v-for="item in stepDefine.items"  v-bind:key="item.id" :value="item.id">
                            {{item.stepTitle}}
                          </a-select-option>
                        </a-select-opt-group>
                      </a-select>

                    </a-form-model-item>
                    <a-form-model-item label="节点名称">
                      <a-input v-model="newStep.stepTitle" />
                    </a-form-model-item>
                    <a-form-model-item label="节点描述">
                      <a-input v-model="newStep.stepDesc" />
                    </a-form-model-item>
                    <a-form-model-item label="时间">
                      <a-date-picker v-model="newStepDate" @change="changeHandler" show-time placeholder="请选择日期和时间"  />
                    </a-form-model-item>
                  </a-form-model>
                  <a-button type="primary" style="margin-top:12px;" block @click="addStep()">确定</a-button>
                </div>
                <a-button type="primary" icon="plus" @click="perAddStep()" >添加节点</a-button>
              </a-popover>
            </div>
            
          </div>
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-button slot="actions" shape="circle" @click="editStep(item,index)" icon="edit" />
              <a-popconfirm slot="actions" placement="topRight"
                title="确定要删除这条记录吗?"
                ok-text="删除"
                cancel-text="取消"
                @confirm="confirmDeleteStep(item,index)"
              >
                <a-button type="danger" shape="circle" icon="delete" />
              </a-popconfirm>
              <a-list-item-meta label
                :description="item.stepDesc"
              >
                <span slot="title">{{ item.stepTitle }}</span>
              </a-list-item-meta>
              <div>{{ formatDate(item.stepAt) }}</div>
            </a-list-item>
          </a-list>
        </a-row>
        
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          取消
        </a-button>
        <a-button type="primary" :loading="editSpin" @click="saveTrailData">
          保存
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import moment from 'moment';

let that = null;
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    that.selectRows = selectedRows;
  },
  onSelect: (record, selected, selectedRows) => {
    that.selectRows = selectedRows;
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    that.selectRows = selectedRows;
  },
};

const fData = function(date, fmt) {
      date = new Date(date);
      if (typeof(fmt) === "undefined") {
          fmt = "yyyy-MM-dd";
      }
      if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      let o = {
          'M+': date.getMonth() + 1,
          'd+': date.getDate(),
          'H+': date.getHours(),
          'm+': date.getMinutes(),
          's+': date.getSeconds()
      }
      for (let k in o) {
          if (new RegExp(`(${k})`).test(fmt)) {
              let str = o[k] + ''
              fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
          }
      }
      return fmt
    }

const columns = [
  {
    title: '客户编号',
    dataIndex: 'customerCode',
    width:120,
    scopedSlots: { customRender: 'customerCode' }
  },{
    title: '入库号',
    width:120,
    dataIndex: 'storageCode',
    scopedSlots: { customRender: 'storageCode' }
  },{
    title: '运输商',
    width:120,
    dataIndex: 'carrier'
  },
  {
    title: '运单号',
    width:120,
    dataIndex: 'trackingNo',
    scopedSlots: { customRender: 'trackingNo' }
  },
  {
    title: '货运方式',
    width:100,
    align: 'center',
    dataIndex: 'trailType'
  },
  {
    title: '清关车/柜号',
    width: 130,
    dataIndex: 'customerCode1'
  },
  {
    title: '当前状态',
    dataIndex: 'lastStatus',
    scopedSlots: { customRender: 'lastStatus' }
  },
  {
    title: '报表/更新时间',
    width:165,
    dataIndex: 'updateAt',
    scopedSlots: { customRender: 'time' }
  },
  {
    title: '操作',
    width:130,
    align: 'right',
    dataIndex: 'id',
    scopedSlots: { customRender: 'option' }
  }
];


export default {
  data() {
    return {
      moment,
      rowSelection,
      reportBuilding:false,
      reportFile:null,
      selectRows:[],
      searchLoading:false,
      editSpin:false,
      pagination: {},
      pageSize:5,
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
      stepDefines:[],
      stepDefineMap:{},
      data:[],
      columns,
      visible:false,
      addStepVisible:false,
      newStep:{index:null,stepTitle:"",stepDesc:"",stepAt:null},
      newStepDate:moment(),
      reportSelect:[],
      editItem: {},
      searchInfo:{
        customerCode:"",
        storageCode:"",
        trackingNo:""
      },
      uploadInfo:{
        status : false,
        progress : 100
      },
    };
  },
  mounted(){
    that = this;
    this.$ready(()=>{
      this.loadTrailStepDefines();
      this.loadTrailDatas(1);
    });
    
  },
  methods:{
    formatDate:fData,

    buildReport(){
      if(this.selectRows.length < 1){
        this.$notification.error({
          message: '生成失败',
          duration: 1.5,
          description: '请先选择需要导出的数据',
        });
        return;
      }

      let ids = [];

      for(var item of this.selectRows){
        ids[ids.length] = item.id;
      }

      this.reportBuilding = true;
      this.$axios.post("/trail/report", ids).then(resp=>{
        this.reportBuilding = false;
        if(resp.data && resp.data["code"] == 200){
          this.$notification.success({
            message: '报表生成成功!',
            description: '下载地址已经在按钮旁,导出历史请访问"报表管理"'
          });
          resp.data.data.src = this.baseURL+"/trail/download/"+resp.data.data["reportKey"];
          this.reportFile = resp.data.data;
        }else{
          this.$notification.error({
            message: '数据获取失败',
            description:
              resp.data.msg,
          });
        }
      })
    },

    pageSizeChange(value){
      this.pageSize = value;
      this.pagination.current = 1;
      this.loadTrailDatas(1);
    },

    changeHandler(value,mode){
      if(this.newStep){
        this.newStep.stepAt = value.valueOf();
        this.newStep.date = value;
      }
      console.info(this.newStep.date.valueOf(),value.valueOf());
    },

    importHandleChange(param){
      let file = param.file;
      if (file.status == 'uploading'){
        this.uploadInfo.status = true;
      }else{
        this.uploadInfo.status = false;
        if(file.response){
          if(file.response.code == 200){
            this.$notification.success({
              message: '上传成功',
              description:'成功导入 '+file.response.data+' 条数据.',
            });
            this.loadTrailDatas(this.pagination.current);
          }else{
            let msg = "";
            if(file.response.data){
              for (var ee of file.response.data){
                msg += ee+"\n";
              }

              this.$notification.error({
                message: '导入失败',
                duration: null,
                description: function(h){
                  return h("pre",msg);
                },
              });
            }else{
              this.$notification.error({
                message: '导入失败',
                duration: null,
                description: file.response.msg,
              });
            }
            
            
          }
        }else{
          console.error(file.response)
          this.$notification.error({
            message: '导入失败',
            description:'服务器没有返回正确信息.'+file.response,
          });
        }
      }
    },

    handleTableChange(pagination, filters, sorter){
      this.loadTrailDatas(pagination.current);
    },

    saveTrailData(){
      this.editSpin = true;

      let saveDate = this.$_.cloneDeep(this.editItem);
      

      if(!saveDate.customerCode ||!saveDate.storageCode ||!saveDate.trackingNo){
        this.$notification.error({
            message: '错误',
            description:'客户编号 / 入库单号 / 运单号 必须填写',
          });
        this.editSpin = false;
        return 
      }

      console.info(saveDate.editReportDate);
      if(saveDate.editReportDate){
        saveDate.reportDate = saveDate.editReportDate.valueOf();
      }
      console.info(saveDate.reportDate);

      if (saveDate.steps){
        saveDate.steps.forEach(element => {
          if(typeof element.stepAt === 'string'){
            element.stepAt = new Date(element.stepAt).getTime();
          }
        });

      }else{
        saveDate.steps = [];
      }
      
      this.$axios.post("/trail/save", saveDate).then(resp=>{
        if(resp.data && resp.data["code"] == 200){
          this.editSpin = false;
          this.$notification.success({
            message: '保存成功!'
          });
          this.loadTrailDatas(this.pagination.current);
          this.onClose();
        }else{
          this.$notification.error({
            message: '数据获取失败',
            description:
              resp.data.msg,
          });
        }
      })
    },

    sortStepsByTime(arr){
      return arr.sort((a,b)=>{
        return b.stepAt - a.stepAt;
      });
    },

    stepDefineSelected(v){
      let data = this.stepDefineMap[v];
      if(data){
        this.newStep.stepTitle = data.stepTitle;
        this.newStep.stepDesc = data.stepDesc;
      }
    },

    perAddStep(){
      this.newStep = {index:null, stepTitle:"",stepDesc:"",stepAt:null};
      this.newStepDate = moment();
    },

    addStep(){
      if(!this.newStep.stepAt){
        this.newStep.stepAt = this.newStepDate.valueOf();
      }
      if(this.newStep.stepTitle && this.newStep.stepAt){
        let arr = this.editItem.steps;
        if(!arr){
          arr = [];
        }
        if(!this.newStep.id && !this.newStep.newStep){
          this.newStep.newStep = true;
          arr.push(this.newStep);
        }

        arr = this.sortStepsByTime(arr);
        this.editItem.steps = arr;

        this.addStepVisible = false;


      }else{
        this.$notification.error({
          message: '添加轨迹节点失败',
          description:'必须录入标题和时间',
        });
      }
    },

    editStep(item,index){
      this.newStep = item;
      let time = item.stepAt+0;
      if(item.stepAt){
        this.newStepDate = moment(time);
      }else{
        this.newStepDate = moment();
      }
      
      this.addStepVisible = true;

    },

    confirmDeleteStep(item,index){
      this.editItem.steps.splice(index, 1);
    },

    confirmDelete(item) {
      this.$axios.post("/trail/delete/"+item.id).then(resp=>{
        if(resp.data && resp.data["code"] == 200){
          this.loadTrailDatas(this.pagination.current);
        }else{
          this.$notification.error({
            message: '数据删除失败',
            description:
              resp.data.msg,
          });
        }
      })
    },

    cancel(e) {
    },

    startEditor(row) {
      let item = this.$_.cloneDeep(row);

      if(item.reportDate){
        item.editReportDate = moment(item.reportDate)
      }else{
        item.editReportDate = null;
      }
      
      this.editItem = item;
      this.visible = true;
      this.editSpin = false;
    },
    onClose() {
      this.visible = false;
    },

    loadTrailStepDefines(){
      this.$axios.get("/trail/step-defines").then(resp=>{
        if(resp.data && resp.data["code"] == 200){
          this.stepDefines = resp.data.data;
          let map = {};
          for(let x in this.stepDefines){
            for(let y in this.stepDefines[x].items){
              let stepItem = this.stepDefines[x].items[y];
              map[stepItem.id] = stepItem;
            }
          }
          this.stepDefineMap = map;
        }else{
          this.$notification.error({
            message: '数据获取失败',
            description:
              resp.data.msg,
          });
        }
      })
    },

    loadTrailDatas(page){
      this.searchLoading = true;
      ///trail/page
      this.$axios.get("/trail/page",{
        params:{
          pageNo:page,
          pageSize:this.pageSize,
          customerCode:this.searchInfo.customerCode,
          storageCode:this.searchInfo.storageCode,
          trackingNo:this.searchInfo.trackingNo
        }
      }).then(resp=>{
        this.searchLoading = false;
        if(resp.data && resp.data["code"] == 200){
          this.data = resp.data.data.records;
          // let paginationInfo = {...this.pagination}
          // console.info(paginationInfo, this.pagination);

          // paginationInfo.current = resp.data.data.current;
          // paginationInfo.total = resp.data.data.total;
          // paginationInfo.pageSize = resp.data.data.pageSize;

          // this.pagination = paginationInfo;

          const pagination = { ...this.pagination };
          pagination.current = parseInt(resp.data.data.current+"");
          pagination.pageSize = this.pageSize;
          pagination.total = parseInt(resp.data.data.total+"");
          this.pagination = pagination;

        }else{
          this.$notification.error({
            message: '数据获取失败',
            description:
              resp.data.msg,
          });
        }
      })
    }
  }
};
</script>

<style>
.ant-table-title{
  text-align: left !important;
}
.rightTools{
  float: right;
}
.ant-form-item{
  margin-bottom: 6px !important;
}
</style>
